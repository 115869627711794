<template>
  <section class="home">
    <img alt="" :src="mobileHeaderImage" class="img-fluid">
    <h1 class="w-bar">{{ welcomeTitle }}</h1>
    <p v-if="applicationEnabled">{{ welcomeMessage }}</p>
    <p v-else>{{ $t('intro_tempDisabled') }}</p>
    <div class="form-row" v-if="!this.$store.state.typeSelectionActive && applicationEnabled">
      <h1>{{ $t('message_title') }}</h1>
      <p>{{ $t('selector_content') }}</p>
      <div class="agb">
        <label class="switch">
          <input type="checkbox" v-model="agbAccepted">
          <span class="slider round"></span>
        </label>
        <p v-html="$t('message_agb')"></p>
      </div>
      <Rules v-if="this.$store.state.areRulesActive"/>
      <ImageUploader v-if="applicationEnabled && this.imageUploadEnabled"
                     :minimal="true"
                     :button-disabled="!agbAccepted"
                     @overlaynow="displayOverlay"
      />

      <MButton :label="$t('btn_uploadText')"
               style="margin-top: 8px;"
               :state="!agbAccepted"
               @click.native="makeSelection('text')"
      />
    </div>
    <Message v-if="applicationEnabled && this.$store.state.selectedType === 'text'"
             :image-upload-enabled="imageUploadEnabled"
             @overlaynow="displayOverlay"
    />

    <div class="lottie-overlay-container"
         v-if="isOverlayActive && currentLottieAnimation === 'text'">
      <div class="overlay">
        <lottie-vue-player :src="`${lottieAnimationText}`"
                           backgroundColor="transparent"
                           :autoplay="options.autoplay"
                           :player-controls="options.playerControls"
                           :loop="options.loop"
                           v-if="currentLottieAnimation === 'text'"
                           style="width: 100%; height:400px; background: transparent;">
        </lottie-vue-player>
      </div>
    </div>
    <div class="lottie-overlay-container"
         v-if="isOverlayActive && currentLottieAnimation === 'image'">
      <div class="overlay">
        <lottie-vue-player :src="`${lottieAnimationImage}`"
                           backgroundColor="transparent"
                           :autoplay="options.autoplay"
                           :player-controls="options.playerControls"
                           :loop="options.loop"
                           style="width: 100%; height:400px; background: transparent;">
        </lottie-vue-player>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Message from '@/views/Message.vue';
import ImageUploader from '@/views/Image.vue';
import MButton from '@/components/MButton.vue';
import Rules from '@/components/Rules.vue';
import axios from 'axios';
import lottieAnimation from '@/assets/scanner.json';
import lottieAnimationText from '@/assets/scanText.json';
import lottieAnimationImage from '@/assets/scanImage.json';

export default {
  name: 'Home',
  components: {
    Message,
    ImageUploader,
    MButton,
    Rules,
  },
  sockets: {
    connect() {
      console.log('socket connected');
    },
    updatedSettings(val) {
      console.log(val);
      this.applicationEnabled = val.status;
      this.$store.state.isApplicationEnabled = val.status;
      this.$store.state.messageDisplayTime = +val.displayTime;
    },
  },
  data() {
    return {
      currentLottieAnimation: '',
      applicationEnabled: false,
      imageUploadEnabled: false,
      mobileHeaderImage: '',
      welcomeTitle: '', // this.$i18n.t('welcome'),
      welcomeMessage: '', // this.$i18n.t('into_isEnabled'),
      agbAccepted: false,
      isRulesActive: false,
      isOverlayActive: false,
      lottieAnimation: JSON.stringify(lottieAnimation),
      lottieAnimationText: JSON.stringify(lottieAnimationText),
      lottieAnimationImage: JSON.stringify(lottieAnimationImage),
      options: {
        autoplay: true,
        playerControls: false,
        loop: true,
      },
    };
  },
  watch: {
    '$store.state.selectedType': function () {
      if (this.$store.state.selectedType !== '') {
        this.$store.state.typeSelectionActive = true;
        this.$store.state.showBackButton = true;
      }
    },
    '$i18n.locale': function () {
      axios.get(`${process.env.VUE_APP_API_URL}/settings/content`)
        .then((response) => {
          this.mobileHeaderImage = `${process.env.VUE_APP_API_URL}/images/${response.data.data[1].metavalue}`;
          if (this.$i18n.locale === 'de') {
            this.welcomeTitle = `${response.data.data[2].metavalue}`;
            this.welcomeMessage = `${response.data.data[3].metavalue}`;
          } else if (this.$i18n.locale === 'fr') {
            this.welcomeTitle = `${response.data.data[4].metavalue}`;
            this.welcomeMessage = `${response.data.data[5].metavalue}`;
          } else {
            this.welcomeTitle = `${response.data.data[6].metavalue}`;
            this.welcomeMessage = `${response.data.data[7].metavalue}`;
          }
        });
    },
  },
  methods: {
    whichDeviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return true;
      }
      if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return true;
      }
      return false;
    },
    openRules() {
      document.body.classList.add('no-scroll');
      this.$store.state.areRulesActive = true;
      this.$store.state.showBackButton = true;
    },
    makeSelection(type) {
      if (!this.agbAccepted) {
        return;
      }
      this.$store.state.selectedType = type;
      this.$store.state.typeSelectionActive = true;
      this.$store.state.showBackButton = true;
    },
    displayOverlay(val) {
      console.log(val);
      if (!val.enabled) {
        this.isOverlayActive = false;
      } else {
        this.isOverlayActive = true;
        if (val.type === 'text') {
          this.currentLottieAnimation = 'text';
        } else {
          this.currentLottieAnimation = 'image';
        }
      }
    },
  },
  updated() {
    if (document.querySelector('.rulesOpener')) {
      document.querySelector('.rulesOpener')
        .addEventListener('click', () => {
          this.openRules();
        });
    }
  },
  mounted() {
    console.log('mounted');
    if (document.querySelector('.rulesOpener')) {
      document.querySelector('.rulesOpener')
        .addEventListener('click', () => {
          this.openRules();
        });
    }

    axios.get(`${process.env.VUE_APP_API_URL}/settings`)
      .then((response) => {
        this.applicationEnabled = (response.data.data[0].metavalue !== '0');
        this.$store.state.isApplicationEnabled = (response.data.data[0].metavalue !== '0');
        this.imageUploadEnabled = (response.data.data[35].metavalue !== '0');
      });

    axios.get(`${process.env.VUE_APP_API_URL}/settings/content`)
      .then((response) => {
        this.mobileHeaderImage = `${process.env.VUE_APP_API_URL}/images/${response.data.data[1].metavalue}`;
        if (this.$i18n.locale === 'de') {
          this.welcomeTitle = `${response.data.data[2].metavalue}`;
          this.welcomeMessage = `${response.data.data[3].metavalue}`;
        } else if (this.$i18n.locale === 'fr') {
          this.welcomeTitle = `${response.data.data[4].metavalue}`;
          this.welcomeMessage = `${response.data.data[5].metavalue}`;
        } else {
          this.welcomeTitle = `${response.data.data[6].metavalue}`;
          this.welcomeMessage = `${response.data.data[7].metavalue}`;
        }
      });

    if (this.$cookies.get('MFestMessageDisplayed')) {
      console.log(this.$cookies.get('MFestMessageDisplayed'));
    }

    this.$store.state.selectedType = '';
    this.$store.state.imagePath = '';
    this.$store.state.typeSelectionActive = false;
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 19px 15px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

h1 {
  position: relative;
  color: #333;
  text-align: left;
  font-size: 26px;

  &.w-bar {
    margin: 0;

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 7px;
      margin-top: 46px;
      margin-bottom: 20px;
      background: #FF6600;
    }
  }
}

p {
  margin-top: 25px;
  font-size: 16px;
  text-align: left;
}

body.no-scroll {
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.error-text {
  color: #FF0000;
}

section {
  padding: 15px 0;
}

h1 {
  text-align: left;
  font-size: 26px;
  margin: 0 0 20px 0;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.form-row {
  text-align: left;

  label {
    display: block;
  }

  input {
    margin-top: 8px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    padding: 15px;
    font-size: 20px;
    text-transform: uppercase;
    width: calc(100% - 30px);
    color: #000;

    &.hasError {
      color: #ff0000;
      border-color: #ff0000;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FF6600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #FF6600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.agb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;

  p a {
    color: #333;
  }

  ::v-deep span {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
  }
}

a.btn.primary {
  background: #FF6600;
  color: #fff;
  display: block;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  font-size: 20px;
  margin: 0 0;
  border-radius: 4px;
  pointer-events: all;
  touch-action: auto;

  &.disabled {
    background: #ccc;
    pointer-events: none;
    touch-action: none;
  }
}

.lottie-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {

  }
}
</style>
